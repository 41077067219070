import { useEffect } from 'react';
import Head from 'next/head';
import type { GetStaticPropsContext } from 'next';
import { useRouter } from 'next/router';

import { useTranslations } from '@keymono/i18n';
import {
  PublicFooter,
  PublicHeader,
  getDesignSystemTranslations,
} from '@keymono/design-system';

import { useSessionInstance } from '@features/user-accounts';

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  const appTranslations = (
    await import(`../translations/messages/${locale}.json`)
  ).default;

  const designSystemTranslations = await getDesignSystemTranslations(
    locale || 'en'
  );

  /**
   * You can get the messages from anywhere you like. The recommended
   * pattern is to put them in JSON files separated by language.
   * TODO: Get the messages from either a strapi endpoint or other service.
   */
  const allTranslations = {
    ...appTranslations,
    ...designSystemTranslations,
  };

  return {
    props: {
      messages: allTranslations,
    },
  };
}

/**
 * -----------------------------------------------------------------------------
 * Home screen page, the landing screen of the app.
 */
export default function Home() {
  const t = useTranslations('Home');
  const { activeSession } = useSessionInstance();
  const { replace } = useRouter();

  /**
   * If its an existing user, route them to the login page.
   */
  useEffect(() => {
    if (activeSession) {
      replace('/auth/login');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Head>
        <title>{t('browserTitle')}</title>
      </Head>
      <div className="flex h-full flex-col">
        <PublicHeader />
        <main>
          <h2 id="main-content-heading" className="sr-only">
            Home Screen main content
          </h2>
          <div
            className="
              mx-auto h-[calc(100vh-200px)] max-w-7xl px-6 pb-8 pt-16 sm:pt-24
              lg:px-8 lg:pt-32
            "
          >
            <p>{t('title')}</p>
          </div>
        </main>
        <PublicFooter />
      </div>
    </>
  );
}
